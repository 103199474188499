// const BASE_URL = "http://162.19.146.135:1468/";
// const BASE_URL ="http://127.0.0.1:5007/node_api"
// const BASE_URL ="http://132.148.72.210:5000/node_api"
const BASE_URL = "https://admin.coachcalloway.com/node_api/"
// const BASE_URL = "https://adminjimmie.iossmlm.com/node_api/"
// const BASE_URL = "https://api.wappss.com/node_api";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";
const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
